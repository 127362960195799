.footer0-wrapper {
  // background-color: $template-bg-color;
  background-color: transparent;
  height: 80px;
  overflow: hidden;
  position: absolute;
  bottom: -50px;
  .footer0 {
    height: 100%;
    padding: 0 24px;
    line-height: 80px;
    text-align: center;
    color: $template-footer-text-color;
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .footer0-wrapper {
    .footer0 {
      font-size: 12px;
      &.home-page {
        padding: 0;
      }
      >div {
        width: 90%;
        margin: auto;
      }
    }
  }
}
