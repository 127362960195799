@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.help-button {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .anticon {
    font-size: 24px;
  }
}

.welcome-modal {
  .ant-modal-footer {
    text-align: center !important;
  }
}

.mobile-menu-container {
   text-align: center !important;
   width: 100%;
   background-color: #fff;
   position: fixed;
   bottom: 0;
   border-top: 1px solid #cacaca !important;
   z-index: 999;

   .order-menu {
     width: 350px;
     margin: 0 auto !important;

     .menu-item {
       text-align: center;
       padding-top: 10px !important;
       line-height: 30px;
       background-color: #fff;
       padding-left: 11px !important;
       padding-right: 11px !important;

       .title {
         padding: 0;
       }

       .icon {
         .anticon {
           font-size: 30px;
         }
       }
     }
   }
}

.login-form {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -160px;
  margin-left: -165px;
  width: 330px;
  height: 190px;
  // border: 1px solid red;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #cdcdcd;
  // background-opacity: 0.85;
}

.ant-layout-content {
  max-width: 2000px;
  margin: 0 auto;
}

.orders-actions {
  padding: 0 24px;
}

.ant-picker-calendar-header {
  padding-left: 24px !important;
  padding-right: 24px !important;
}


// .ant-collapse-header {
  // text-align: center !important;
  // margin-left: 200px;
  // border: 1px solid red;
  // > div, > span {
  //   margin: 0 auto;
  // }
// }

.ant-menu-item-disabled {
  span {
    color: #cacaca;
  }
}

.mobile-add-btn {
  position: fixed;
  right: 20px;
  bottom: 60px;

  &.with-menu {
    bottom: 100px;
  }

  .ant-btn {

    &.ant-btn-circle.ant-btn-lg {
      min-width: 60px;
    }

    &.ant-btn-lg {
      padding: 0;
      height: 60px;
    }
    span {
      font-size: 50px;
      margin-top: -10px;
    }
  }
  z-index: 999;
}

.menu-drawer {
  .ant-drawer-close {
    display: none;
  }

  .ant-menu {
    width: 348px;
  }
}

.ant-pagination {
  padding-right: 10px !important;
}

.layout-content-authenticated {
  padding-bottom: 80px !important;
}

.ant-alert {
  &.registration-alert {
    margin-bottom: 10px;
    border-radius: 4px;
  }

  &.ant-alert-info {
    border: 1px solid $color2;
    background-color: $color2_light;
  }

  &.calculator-alert {
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 4px;

    &.on-mobile {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &.panel-alert {
    margin-top: 25px;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 4px;

    &.on-mobile {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.alert-wrapper {
  max-width: 800px;
  margin: 0 auto;
}

.calculator-help-list {
  margin-left: 20px;
  margin-top: 10px;
}

.calculator-results-left {
  text-align: right;
}

.calculator-results-right {
  text-align: left;
  display: flex !important;
  align-items: center;
  padding-left: 5px !important;

  > b {
    margin-right: 5px;
  }
}

.calculator-results-header-left {
  text-align: right;
}

.calculator-results-header-right {
  text-align: left;
  padding-left: 5px;
}

.calculator-results-collapse {
  margin-top: 10px !important;
  border: none !important;
  background-color: transparent !important;

  .ant-collapse-item, .ant-collapse-content {
    border: none !important;
  }
}

.ant-checkbox-wrapper.register-agreement-checkbox > span:not(.ant-checkbox)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-form {
  &.register-form {
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;

    .ant-form-item-control-input-content {
      text-align: left;
    }
  }

  &.profile-form {
    max-width: 550px;
    margin: 0 auto;
    padding: 0 20px 20px 20px;

    .ant-select-selection-item {
      text-align: left;
    }
  }

  .help-icon-mobile {
    font-size: 18px !important;
  }
}

.banner1 {
  .banner1-register {
    // width: 800px !important;
  }
}

.mobile-list {
  .ant-list-item-meta-title, .ant-list-item-meta-description {
      text-align: left;
      padding-left: 10px;
  }

  .ant-list-pagination {
    text-align: left;
    padding-left: 10px;
  }
}

@media screen and (max-width: 575px) {
  .login-form {
    height: 320px;
  }
  .ant-form {
    &.register-form {

      .ant-form-item-control-input-content {
        text-align: center;
      }
    }
  }

  .calculator {
    .calc-params {
      .ant-form-item-label {
        flex-basis: 58.3333% !important;
        // text-align: right !important;
        padding-right: 5px !important;

        &:after {
          content: ":";
        }
      }

      .ant-form-item-control {
        // flex-basis: 25% !important;
      }
    }
  }

  .calculator-results-header-left {
    text-align: center;
  }

  .calculator-results-header-right {
    text-align: center;
    padding-left: 0;
  }
}

@media screen and (max-width: 510px) {
  .calculator {
    .calc-params {
      .ant-form-item-label {
        flex-basis: 100% !important;
        text-align: left !important;
        padding-right: 0 !important;

        &:after {
          content: "";
        }
      }
    }
  }
}

.header0 {
  // border-bottom: solid 1px #e8e8e8;
  // box-shadow: 0 0 30px #f3f1f1;
  // height: 44px;
  // line-height: 44px;
  // background: #fff;
  // padding: 0;

  .ant-menu-horizontal {
    border-bottom: none;
  }



  .logo {
    float: left;
    margin-left: 18px;
  }
}

.hamburger {
  float: right;
  display: block;
  position: absolute;
  width: 20px;
  height: 14px;
  cursor: pointer;
  top: 20px;
  right: 16px;
  z-index: 1001 !important;

  em {
    z-index: 1001 !important;
    display: block;
    width: 100%;
    height: 2px;
    // background: #fff;
    background: #000;
    margin-top: 6px;
    transition: transform .3s ease-in-out, opacity .3s ease-in-out;
  }

  :first-child {
    margin-top: 0;
  }

  &.open {
    em {
      &:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
        background: #000;
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
        background: #000;
      }
    }
  }
}

:not(.ant-picker-cell-in-view) {
  .calendar-badge > sup {
    background-color: #cacaca;
    margin: 0 auto;
  }
}

.ant-picker-cell-in-view {
  .calendar-badge > sup {
    background-color: $color2;
    margin: 0 auto;
  }
}



.ant-picker-calendar {
  .calendar-badge-wrapper {
    text-align: center;
    margin-top: 20px;
  }
}

.ant-drawer-body {
  padding: 0 !important;

  .ant-menu-inline {
    border-right: none;

    .ant-menu-item {
      margin: 0 !important;
    }
  }
}

.pwa-prompt-ios-icon {
  height: 20px;
  margin-left: 10px;
}

.calculator {
  max-width: 800px;
  margin-left: auto !important;
  margin-right: auto !important;

  .ant-form-item {
    margin-bottom: 8px !important;
  }

  .dynamic-delete-button {
    font-size: 18px;
    line-height: 38px;
  }
  .ant-select-open {
    .ant-select-selection-placeholder {
      color: transparent !important;
    }
  }

  input:focus::placeholder {
    color: transparent;
  }

  .ant-card {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;

    &.on-mobile {
      margin-bottom: 10px;
    }

    &:not(.on-mobile) {
      border-bottom: none !important;
    }
  }

  .ant-card-head-title {
    text-align: left;
    font-weight: bold;
  }
}

.menu-profile {
    .anticon-user {
      font-size: 20px !important;
    }
}

.ant-select-selection-item
{
  text-align: left;
}

// calculator
.calculator-actions-menu {
  .ant-menu-item-active {
    color: inherit !important;
  }
}

.calculator-help-icon {
  &.anticon {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.45);
  }
}

.calculator-results-divider-wrapper {
  width: 200px;
  margin: 0 auto;
}

.calculator-help-popup {
  padding: 20px;
  overflow-y: auto;
  max-height: 50vh;
}

.promo-help-popup {
  padding: 20px;
  overflow-y: auto;
  max-height: 50vh;

  h2 {
    margin-bottom: 15px;
  }
}

.calculator-help-drawer {
  padding: 20px;
}

.ant-popover {
  max-width: 500px;
}

.adm-dropdown-nav {
  border-bottom: none !important;

  .adm-dropdown-item-title-arrow {
    color: rgba(0, 0, 0, 0.85) !important;
  }

  .adm-dropdown-item-title {
    padding-right: 0 !important;
  }
}

.calculator-help-popup-close {
  float: right;
  &.ant-btn {
    padding-top: 0;
    padding-right: 0;
  }
  .anticon {
    font-size: 22px;
  }
}

.input-100p {
  width: 100% !important;
}

.calculator-mobile {
  .on-mobile {
    .ant-card-body {
      padding: 10px;
    }
  }

  .toolbox {
    row-gap: 0 !important;
  }

  .ant-card-bordered {
    border-left: none;
    border-right: none;
  }

  :not(:first-child) {
    .ant-card-bordered {
      border-top: none;

      .ant-card-head-title {
        padding-top: 2px;
      }
      .ant-card-extra {
        margin-top: -12px;
      }
    }
  }
}

.form-item-actions {
  text-align: left;
  padding-left: 10px !important;
}

.calc-params {
  .ant-row {
    > div:nth-child(2) {
      text-align: left;
    }
  }
}

.ant-image-mask-info {
  visibility: hidden;
}

// promo list and show
.promo-list {
  .ant-list-item-extra {
    order: 1;
  }

  .ant-list-item-main {
    order: 2;
    padding-bottom: 30px;
  }
}

.promo-list {
  margin-left: 20px;
}

.mobile-image-preview-close {
  position: fixed;
  top: 9px;
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 50%;
  right: 11px;
  font-size: 23px;
  color: #000;
  z-index: 1002;
}

.ant-image-img {
  padding: 5px;
}

.images-more {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.promo-video-iframe {
  width: 40vw;
  height: 22.5vw;
  max-width: 480px;
  max-height: 270px;
}

.promo-video-iframe-mobile {
  width: 80vw;
  height: 45vw;
}

.verified-promo {
  font-size: 16px;

  .anticon {
    color: rgb(82, 196, 26);
    font-size: 35px;
  }
}

.promo-header {
  text-align: right;
  padding-left: 20px;
  padding-right: 20px;
}

.promo-content {
  text-align: left;
}
// promo list and show end

// static content
.static-content {
   text-align: left;
   max-width: 1000px;
   margin: 30px auto;
   padding-left: 30px;
   padding-right: 30px;
   font-size: 16px;
   min-height: 60vh;
}

.colored {
  font-weight: bold;
}

// user profile
.profile-skeleton {
  max-width: 550px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
