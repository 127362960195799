@import './common.scss';
@import './custom.scss';
@import './content.scss';
@import './nav0.scss';
@import './banner0.scss';
@import './banner1.scss';
@import './banner2.scss';
@import './content0.scss';
@import './content1.scss';
@import './content13.scss';
@import './footer1.scss';
@import './panelFooter.scss';
@import './content13.scss';
@import './feature2.scss';
@import './feature5.scss';
@import './content8.scss';

.back-to-hompage {
  .ant-page-header-back-button,
  .ant-page-header-heading-title {
    color: #fff;
  }
}
