$content13: content13;

.#{$content13} {
  &-button {
    border: 1px solid #000 !important;
    color: #000 !important;
    background: transparent;
    box-shadow: 0 0 0 transparent;
    font-size: 16px;
    height: 40px;
    transition: background .45s $ease-out, box-shadow .45s $ease-out;
    margin-top: 20px;
    &:hover {
      color: #fff;
      border-color: #fff;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
    }
    &:focus {
      color: #fff;
      border-color: #fff;
    }
    &.queue-anim-leaving {
      width: auto;
    }
  }
}

.#{$content13}-wrapper {
  min-height: 380px;
  background: url("../../images/content13.png") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
  &.home-page-wrapper {
    .title-wrapper {
      margin-bottom: 32px;
    }
  }
  .title-content {
    line-height: 32px;
  }
}

@media screen and (max-width: 767px) {
  .#{$content13}-wrapper {
    padding-bottom: 0;
  }
}
